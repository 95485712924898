import { zodResolver } from '@hookform/resolvers/zod';
import {
  getLocalTimeZone,
  parseDate,
  today,
  CalendarDate,
} from '@internationalized/date';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Skeleton } from 'baseui/skeleton';
import { isAfter, isDate, isToday, startOfMonth, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useEffect, useRef } from 'react';
import {
  Button as AriaButton,
  Calendar as AriaCalendar,
  DateInput as AriaDateInput,
  DateInputProps as AriaDateInputProps,
  DatePicker as AriaDatePicker,
  DatePickerProps as AriaDatePickerProps,
  DateSegment as AriaDateSegment,
  Dialog as AriaDialog,
  Group as AriaGroup,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateValue,
} from 'react-aria-components';
import {
  Calendar as CalendarIcon,
  ChevronLeft,
  ChevronRight,
  User as UserIcon,
} from 'react-feather';
import {
  Control,
  FieldPath,
  FieldValues,
  SubmitHandler,
  useController,
  useForm,
  UseFormSetValue,
} from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { tv } from 'tailwind-variants';
import { z } from 'zod';

import { FieldErrorMessage, fieldStyles } from 'components/forms/fieldset';
import { Pagination } from 'components/Pagination';
import { QueryResolver } from 'components/QueryResolver';
import { createTableBuilder } from 'components/Table';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader } from 'components/ui/card';
import { Heading } from 'components/ui/heading';
import { Label } from 'components/ui/label';
import { Popover } from 'components/ui/popover';
import { Select, SelectField, SelectItem } from 'components/ui/select';
import { Strong, Text } from 'components/ui/text';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { AuditLoginService } from 'services/api/audit/endpoints';
import { AuditLogin } from 'services/api/audit/types';
import { useEmployeesQuery } from 'services/api/employees/endpoints';
import { paginatedParamsSchema } from 'services/api/types.shared';
import {
  cn,
  formatDate,
  stripUndefined,
  toPayloadDateFormat,
} from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';
import { useValidatedSearchParams } from 'utils/hooks/useValidatedSearchParams';
import { toOptionalSchemaProperties } from 'utils/schemas';

function separateDateAndTime(dateTimeString: string | null): {
  date: string;
  time: string;
} {
  if (!dateTimeString) {
    return { date: '---', time: '---' };
  }
  const date = new Date(dateTimeString);
  return {
    date: formatDate(date, 'yyyy-MM-dd'),
    time: formatDate(date, 'hh:mm a'),
  };
}

const setToStartOfDayUTC = (date: Date) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localStartOfDay = new Date(date.setHours(0, 0, 0, 0));
  return zonedTimeToUtc(localStartOfDay, localTimeZone);
};

const setToEndOfDayUTC = (date: Date) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localEndOfDay = new Date(date.setHours(23, 59, 59, 999));
  return zonedTimeToUtc(localEndOfDay, localTimeZone);
};

const AuditLoginTable = createTableBuilder<AuditLogin>();

const schema = z.object({
  userId: z.coerce.number({
    required_error: 'Required',
    invalid_type_error: 'Required',
  }),
  dateFrom: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === 'invalid_date') {
        return { message: 'Required' };
      }
      return { message: defaultError };
    },
  }),
  dateTo: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === 'invalid_date') {
        return { message: 'Required' };
      }
      return { message: defaultError };
    },
  }),

  // timestamp to indicate submission
  ts: z.coerce.number().optional(),
});

// Since using `superRefine` returns a ZodEffect we cannot manipulate the object anymore so we extract it to another schema
const formFieldsSchema = schema.superRefine((data, ctx) => {
  if (isAfter(data.dateFrom, data.dateTo)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['dateFrom'],
      message: 'Start date later than end date',
    });
  }
});

type FormFields = z.infer<typeof schema>;

export default function LoginHistory() {
  const { t } = useLocale();

  const searchParamValues = useValidatedSearchParams(
    toOptionalSchemaProperties(schema).optional(),
  ).values;

  const query = useEmployeesQuery();

  const { handleSubmit, setValue, control } = useForm<FormFields>({
    defaultValues: {
      ...searchParamValues,
      userId: searchParamValues?.userId ?? 0,
      dateFrom: searchParamValues?.dateFrom ?? startOfMonth(new Date()),
      dateTo: searchParamValues?.dateTo ?? startOfDay(new Date()),
    },
    resolver: zodResolver(formFieldsSchema),
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormFields> = (data: FormFields) => {
    const fromDate = setToStartOfDayUTC(new Date(data.dateFrom));
    const toDate = setToEndOfDayUTC(new Date(data.dateTo));

    navigate({
      pathname: '.',
      search: `?${new URLSearchParams(
        stripUndefined({
          ts: Date.now(),
          userId: data.userId ? data.userId : undefined,
          dateFrom: fromDate.toISOString(),
          dateTo: toDate.toISOString(),
        }),
      ).toString()}`,
    });
  };

  const handleEmailClick = (userId: number) => {
    setValue('userId', userId);
    handleSubmit(onSubmit)();
  };

  return (
    <div className="space-y-8">
      <DashboardContentHeader title={t('Login History')} description="" />
      <Card>
        <CardHeader>
          <Heading>{t('Login Data History')}</Heading>
        </CardHeader>
        <CardContent>
          <form
            className="grid gap-4 md:grid-cols-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <QueryResolver
              query={query}
              loadingElement={
                <div className="flex items-end">
                  <Skeleton
                    rows={1}
                    width="100%"
                    overrides={{
                      Row: { style: { height: '44px' } },
                    }}
                    animation
                  />
                </div>
              }
            >
              {(employees) => {
                const allEmployees = employees ?? [];

                return (
                  <SelectField control={control} field="userId">
                    <Label>{t('Employees')}</Label>
                    <Select
                      items={[
                        {
                          id: 0,
                          employeeName: 'All Employees',
                        },
                        ...[...allEmployees]
                          .sort((a, b) => {
                            if (a.firstname < b.firstname) {
                              return 1;
                            } else if (a.firstname > b.firstname) {
                              return -1;
                            } else {
                              return 0;
                            }
                          })
                          .reverse(),
                      ]}
                      variant="default"
                    >
                      {(employee) => (
                        <SelectItem textValue={employee.id.toString()}>
                          <Text slot="label">
                            {'employeeName' in employee
                              ? employee.employeeName
                              : `${employee.firstname} ${employee.lastname}`}
                          </Text>
                        </SelectItem>
                      )}
                    </Select>
                  </SelectField>
                );
              }}
            </QueryResolver>
            <DatePickerField control={control} field="dateFrom">
              <Label>{t('Date From')}</Label>
              <DatePicker />
            </DatePickerField>
            <DatePickerField control={control} field="dateTo">
              <Label>{t('Date To')}</Label>
              <DatePicker />
            </DatePickerField>
            <div className="col-span-3 flex justify-end">
              <Button type="submit">{t('Generate List')}</Button>
            </div>
          </form>
        </CardContent>
        <div className="mt-4 border-t pt-4">
          <AuditLoginList
            setUserId={setValue}
            onEmailClick={handleEmailClick}
          />
        </div>
      </Card>
    </div>
  );
}

type AuditLoginListProps = {
  setUserId: UseFormSetValue<FormFields>;
  onEmailClick: (userId: number) => void;
};

function AuditLoginList({ setUserId, onEmailClick }: AuditLoginListProps) {
  const { t } = useLocale();

  const values = useValidatedSearchParams(
    toOptionalSchemaProperties(
      schema.extend(paginatedParamsSchema.shape),
    ).optional(),
  ).values;

  const query = AuditLoginService.endpoints.auditLogin.useQuery(
    values && Object.keys(values).length !== 0
      ? {
          params: {
            userId: values.userId,
            dateFrom: values.dateFrom
              ? setToStartOfDayUTC(new Date(values.dateFrom)).toISOString()
              : undefined,
            dateTo: values.dateTo
              ? setToEndOfDayUTC(new Date(values.dateTo)).toISOString()
              : undefined,
            page: values.page,
          },
        }
      : skipToken,
    { skip: values === undefined, refetchOnMountOrArgChange: true },
  );

  const loginData = query.data?.list ?? [];
  const totalLoginData = query.data?.totalElements ?? 0;

  const ts = values?.ts;
  const refetch = useRef(query.refetch);
  useEffect(() => {
    if (ts) {
      refetch.current();
    }
  }, [ts]);

  if (!values || Object.keys(values).length === 0) {
    return (
      <div className="rounded-xl border border-dashed py-7 text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          {t('login_history_logs_report_title')}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          <Trans
            i18nKey="login_history_logs_report_description"
            components={{
              1: <span className="font-medium text-brand-primary" />,
            }}
          />
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <Heading>
            {t('Login Data History from')}{' '}
            {values
              ? `${formatDate(values.dateFrom)}-${formatDate(values.dateTo)}`
              : null}
          </Heading>
          <Text>
            <Trans
              i18nKey="total_login_history_logs"
              count={totalLoginData}
              components={{
                1: <Strong />,
              }}
            />
          </Text>
        </div>
      </div>
      <div className="mt-4">
        {query.isFetching || query.isLoading ? (
          <Skeleton rows={5} />
        ) : loginData.length === 0 ? (
          <div className="flex flex-col items-center space-y-2 px-4 py-5 text-center text-sm">
            <UserIcon aria-hidden="true" className="text-gray-400" />
            <h3 className="font-bold text-gray-500">
              {t('No Login History Logs')}
            </h3>
          </div>
        ) : (
          <AuditLoginTable.Table
            data={loginData}
            isError={query.isError}
            isLoading={query.isLoading || query.isFetching}
            loadingMessage={<Skeleton rows={5} />}
            overrides={{
              TableBodyCell: {
                style: { maxWidth: '13rem', width: '13rem' },
              },
            }}
          >
            <AuditLoginTable.Column header={t('Email Address')}>
              {(row) => (
                <button
                  className="text-brand-primary underline"
                  onClick={() => onEmailClick(row.fsaUserId)}
                >
                  {row.email}
                </button>
              )}
            </AuditLoginTable.Column>
            <AuditLoginTable.Column header={t('Technician Name')}>
              {(row) => `${row.firstName} ${row.lastName}`}
            </AuditLoginTable.Column>
            <AuditLoginTable.Column header={t('Date')}>
              {(row) => separateDateAndTime(row.loginTime).date}
            </AuditLoginTable.Column>
            <AuditLoginTable.Column header={t('Login Time')}>
              {(row) => separateDateAndTime(row.loginTime).time}
            </AuditLoginTable.Column>
            <AuditLoginTable.Column header={t('Remote Address')}>
              {(row) => <span className="break-all">{row.remoteAddress}</span>}
            </AuditLoginTable.Column>
          </AuditLoginTable.Table>
        )}
        <div className="mt-3 flex justify-end">
          <Pagination totalItems={query.data?.totalElements} pageSize={25} />
        </div>
      </div>
    </div>
  );
}

const segmentStyles = tv({
  base: 'type-literal:px-0 forced-color-adjust-none forced-colors:text-[ButtonText] inline rounded p-0.5 text-gray-800 caret-transparent outline outline-0 dark:text-zinc-200',
  variants: {
    isPlaceholder: {
      true: 'italic text-gray-600 dark:text-zinc-400',
    },
    isDisabled: {
      true: 'forced-colors:text-[GrayText] text-gray-200 dark:text-zinc-600',
    },
    isFocused: {
      true: 'forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] bg-blue-600 text-white dark:text-white',
    },
  },
});

function DatePickerField<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  children,
  control,
  field: fieldName,
  ...props
}: AriaDatePickerProps<DateValue> & { control: Control<T>; field: TName }) {
  const controller = useController({ control, name: fieldName });
  const field = controller.field;
  const error = !!controller.fieldState.error?.message;

  return (
    <AriaDatePicker
      {...props}
      ref={field.ref}
      name={field.name}
      value={
        field.value && isDate(field.value)
          ? parseDate(toPayloadDateFormat(new Date(field.value)))
          : null
      }
      className={fieldStyles({ className: 'group' })}
      onChange={(selected) =>
        field.onChange(selected.toDate(getLocalTimeZone()))
      }
      onBlur={field.onBlur}
      isInvalid={error}
    >
      {(renderProps) => (
        <>
          {typeof children === 'function' ? children(renderProps) : children}
          <FieldErrorMessage control={control} field={fieldName} />
        </>
      )}
    </AriaDatePicker>
  );
}

function DatePicker() {
  return (
    <>
      <AriaGroup className="relative z-0" data-slot="control">
        <DateInput>
          {(segment) => (
            <AriaDateSegment segment={segment} className={segmentStyles} />
          )}
        </DateInput>
        <AriaButton className="absolute inset-0 flex w-full items-center justify-end rounded pr-3 outline-offset-0">
          <CalendarIcon aria-hidden className="h-4 w-4" />
        </AriaButton>
      </AriaGroup>
      <Popover>
        <AriaDialog className="relative max-h-[inherit] overflow-auto p-6 outline outline-0 [[data-placement]>&]:p-4">
          <Calendar />
        </AriaDialog>
      </Popover>
    </>
  );
}

function Calendar() {
  const now = today(getLocalTimeZone());

  return (
    <AriaCalendar
      aria-label="Calendar"
      isDateUnavailable={(date) => {
        if (date instanceof CalendarDate) {
          return date.compare(now) > 0;
        }
        return false;
      }}
    >
      <header className="flex items-center text-gray-900">
        <Button slot="previous" type="button" variant="plain" square>
          <ChevronLeft className="h-5 w-5" aria-hidden="true" />
        </Button>
        <div className="flex-auto text-center">
          <Heading className="" />
        </div>
        <Button slot="next" type="button" variant="plain" square>
          <span className="sr-only">Next month</span>
          <ChevronRight className="h-5 w-5" aria-hidden="true" />
        </Button>
      </header>
      <CalendarGrid className="mt-5 w-full overflow-hidden rounded bg-white text-sm">
        <CalendarGridHeader>
          {(day) => (
            <CalendarHeaderCell>
              <div className="flex h-10 w-10 items-center justify-center text-gray-500">
                <span>{day}</span>
              </div>
            </CalendarHeaderCell>
          )}
        </CalendarGridHeader>
        <CalendarGridBody>
          {(date) => (
            <CalendarCell date={date} className="">
              {(rp) => (
                <span
                  className={cn(
                    'mx-auto w-10 h-10 flex items-center justify-center ',
                    {
                      'bg-brand-primary-600 text-white rounded':
                        // rp.isSelected && isToday(date.toDate(getTimezone())),
                        rp.isSelected,
                      'rounded-l-lg': rp.isSelectionStart,
                      'rounded-r-lg': rp.isSelectionEnd,
                      rounded: !rp.isSelected,
                      'bg-gray-50 text-gray-200': rp.isDisabled,
                      'line-through text-red-500': rp.isUnavailable,
                      'font-semibold': isToday(date.toDate(getLocalTimeZone())),
                    },
                  )}
                >
                  {date.day}
                </span>
              )}
            </CalendarCell>
          )}
        </CalendarGridBody>
      </CalendarGrid>
    </AriaCalendar>
  );
}

function DateInput(props: AriaDateInputProps) {
  return (
    <div className="relative" data-slot="control">
      <AriaDateInput
        {...props}
        data-slot="control"
        className={cn([
          // Base
          'peer form-input transition block w-full rounded-lg border-zinc-950/10 bg-white px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] text-base text-gray-900 hover:bg-gray-50 focus:bg-gray-50 focus:ring-0',

          // Invalid state
          'data-[invalid]:border-red-500 data-[invalid]:data-[hovered]:border-red-500',

          // Disabled state
          'data-[disabled]:border-zinc-950/20',
        ])}
      />
    </div>
  );
}
