import { AuditLogin, AuditLoginListParams, auditLoginParams } from './types';

import { baseAPI, toPaginatedList } from '../base-api';
import { PaginatedResponse } from '../types.shared';

import { stripUndefined } from 'utils/helpers';
import {
  toOptionalSchemaProperties,
  toSchemaWithDefaults,
} from 'utils/schemas';


const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    auditLogin: builder.query<
      PaginatedResponse<AuditLogin>,
      {
        params: Partial<AuditLoginListParams>;
      }
    >({
      query(arg) {
        const schema = toOptionalSchemaProperties(auditLoginParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 25,
          }).parse(arg.params),
        );

        return {
          url: `audit/login`,
          params,
        };
      },
      transformResponse(
        baseQueryReturnValue,
        meta: { request: Request; response?: Response },
        arg,
      ) {
        return toPaginatedList(baseQueryReturnValue, meta.response?.headers);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useAuditLoginQuery } = endpoints;
export const AuditLoginService = endpoints;
