import { TechStatus, TechStatusListParams, techStatusParams } from './types';

import { baseAPI, toPaginatedList } from '../base-api';
import { PaginatedResponse } from '../types.shared';

import { stripUndefined } from 'utils/helpers';
import {
  toOptionalSchemaProperties,
  toSchemaWithDefaults,
} from 'utils/schemas';

const endpoints = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    techStatus: builder.query<
      PaginatedResponse<TechStatus>,
      {
        params: Partial<TechStatusListParams>;
      }
    >({
      query(arg) {
        const schema = toOptionalSchemaProperties(techStatusParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 25,
          }).parse(arg.params),
        );

        return {
          url: `fsausers/status/history`,
          params,
        };
      },
      transformResponse(
        baseQueryReturnValue,
        meta: { request: Request; response?: Response },
        arg,
      ) {
        return toPaginatedList(baseQueryReturnValue, meta.response?.headers);
      },
    }),
    exportTechStatus: builder.mutation<string, Partial<TechStatusListParams>>({
      query(arg) {
        const schema = toOptionalSchemaProperties(techStatusParams);
        const params = stripUndefined(
          toSchemaWithDefaults(schema, {
            page: 1,
            limit: 25,
          }).parse(arg),
        );

        return {
          url: `fsausers/status/download`,
          params,
          responseHandler: (response) => response.blob(),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useTechStatusQuery, useExportTechStatusMutation } = endpoints;
export const TechStatusService = endpoints;
