import { z } from 'zod';

import { paginatedParamsSchema } from '../types.shared';

export const techStatusParams = paginatedParamsSchema.extend({
  userId: z.coerce.number(),
  dateFrom: z.string(),
  dateTo: z.string(),
});

export type TechStatusListParams = z.infer<typeof techStatusParams>;

export enum TechStatusType {
  ON_DUTY = 'ON_DUTY',
  OFF_DUTY = 'OFF_DUTY',
  START_LUNCH = 'START_LUNCH',
  END_LUNCH = 'END_LUNCH',
}

export const techStatusSchema = z.object({
  fsaUserId: z.number(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string(),
  timestamp: z.string(),
  status: z.nativeEnum(TechStatusType),
  latitude: z.number(),
  longitude: z.number(),
});

export type TechStatus = z.infer<typeof techStatusSchema>;
