import { zodResolver } from '@hookform/resolvers/zod';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { StyledAction, StyledBody } from 'baseui/card';
import type {
  CheckboxOverrides,
  SharedStyleProps as CheckboxSharedProps,
} from 'baseui/checkbox';
import { Checkbox, StyledToggle } from 'baseui/checkbox';
import { StyledCaption } from 'baseui/form-control';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import type { Options } from 'baseui/select';
import { DURATION, useSnackbar } from 'baseui/snackbar';
import { StatefulTabs, Tab } from 'baseui/tabs-motion';
import { Tag } from 'baseui/tag';
import { TimePicker } from 'baseui/timepicker';
import clsx from 'clsx';
import { isBefore, isEqual, set, startOfDay } from 'date-fns';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  AlertCircle,
  ArrowRight,
  MinusCircle,
  Plus,
  PlusCircle,
  RefreshCw,
  X,
  Save,
  XCircle,
  Send,
} from 'react-feather';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  FormProvider,
  get as getFieldError,
  useForm,
  useFormContext,
  UseFormReturn,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { MutationBanner } from 'components/Banner';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { FormController } from 'components/FormController';
import { Description } from 'components/forms/fieldset';
import { Image } from 'components/Image';
import { Input as FormInput } from 'components/Input';
import { QueryResolver } from 'components/QueryResolver';
import { Select } from 'components/Select';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Select as SelectColor, SelectItem } from 'components/ui/select';
import { HeadlessSwitchField, LabeledSwitch } from 'components/ui/switch';
import { DashboardContentHeader } from 'features/DashboardContentHeader';
import { THEME_COLORS, THEME_VALUES } from 'features/reseller';
import { useCurrentUser } from 'services/api/auth/endpoints';
import { isMutationSuccess } from 'services/api/base-api';
import {
  useClientPropertiesQuery,
  useDisconnectQuickbooksMutation,
  useIsQuickbooksIntegratedQuery,
  useSaveClientPropertiesMutation,
  useSyncCustomersMutation,
  useEditClientMutation,
  useClientQuery,
  useSyncItemsMutation,
  useLazyTestSmtpEmailQuery,
} from 'services/api/clients/endpoints';
import {
  CURRENCY_OPTION_VALUES,
  ClientProperty,
} from 'services/api/clients/types';
import { useUploadImageMutation } from 'services/api/utilities/endpoint';
import { setPreferenceValue } from 'store/slices/user-preferences';
import { store, useTypedDispatch, useTypedSelector } from 'store/store';
import theme from 'theme';
import { toPayloadDateFormat, toPayloadTimeFormat } from 'utils/helpers';
import { useLocale } from 'utils/hooks/useLocale';

const DEFAULT_SCHEDULE_COLOR = '#6BC07D';

const settingsSchema = z.object({
  logo: z.object({
    file: z.instanceof(File).array(),
    imageId: z.number(),
  }),
  workingHours: z
    .object({
      start: z.string().datetime(),
      end: z.string().datetime(),
      is24HoursAvailable: z.boolean(),
    })
    .refine(
      (data) => {
        if (data.is24HoursAvailable) {
          return true;
        }
        return !isEqual(new Date(data.end), new Date(data.start));
      },
      {
        message: 'time_cannot_be_equal',
      },
    )
    .refine(
      (data) => {
        if (data.is24HoursAvailable) {
          return true;
        }
        return isBefore(new Date(data.start), new Date(data.end));
      },
      {
        message: 'start_time_later_than_end',
      },
    ),
  currency: z.nativeEnum(CURRENCY_OPTION_VALUES),
  driveTime: z.coerce.number(),
  reviewsUrl: z.coerce.string(),
});

type FormFields = z.infer<typeof settingsSchema>;

export default function SettingIndexPage() {
  const { t } = useLocale();
  const [activeTab, setActiveTab] = useState<string>('General');

  return (
    <div className="flex flex-1 flex-col space-y-5">
      <DashboardContentHeader
        title={t('Settings')}
        description={t('Configure your business settings')}
      />
      <StatefulTabs
        activeKey={activeTab}
        onChange={({ activeKey }) => setActiveTab(activeKey as string)}
        overrides={{
          Root: {
            props: {
              className: 'rounded-lg bg-white mb-4',
            },
          },
          TabList: { props: { className: 'rounded-t-lg' } },
          TabHighlight: {
            props: {
              className: 'bg-brand-primary',
            },
          },
        }}
      >
        <Tab title={t('General')} key="General">
          <SettingsTab />
        </Tab>
        <Tab title={t('Integrations')} key="Integrations">
          <IntegrationsTab />
        </Tab>
        <Tab title={t('SMTP Information')} key="SMTPInformation">
          <SmtpTab setActiveTab={setActiveTab} />
        </Tab>
        <Tab title={t('Notifications')} key="Notifications">
          <NotificationsTab />
        </Tab>
        <Tab title={t('Schedule Slot Colors')} key="ScheduleSlotColors">
          <ScheduleSlotColorsTab />
        </Tab>
      </StatefulTabs>
    </div>
  );
}

/**
 * Workflow
 * - User clicks "Connect" and a new window popup will open.
 * - new window will prompt the user to login to their quickbooks account
 * - once logged in it should redirect back to the integrations tab of page and start `syncing` of clients customers
 *
 * Lacking Implementation
 * - Redirect to /fs-admin/setttings screen after successful login
 * - sync customers automatically after redirect
 * - hide `Connect` button when client is integrated with quickbooks
 *
 * Requirements
 * - Add Quickbooks logo
 * - Need a way to check if client is already authenticated with Quickbooks to only show the `Sync` button once
 * authenticated
 */

function IntegrationsTab() {
  return (
    <ul className="grid flex-1 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-4 font-prompt">
      <li>
        <QuickbooksIntegration />
      </li>
      <li>
        <HelcimIntegration />
      </li>
    </ul>
  );
}

function HelcimIntegration() {
  const { t } = useLocale();
  const [manageIntegration, setManageIntegration] = useState(false);
  const [apiToken, setApiToken] = useState('');
  const [draftToken, setDraftToken] = useState('');
  const { enqueue } = useSnackbar();
  const clientId = useCurrentUser().data?.clientId ?? 0;
  const [saveProperties, mutation] = useSaveClientPropertiesMutation();
  const propertiesQuery = useClientPropertiesQuery();
  const isConnected = propertiesQuery.data?.some(
    (p) => p.propertyName === 'helcimApiToken' && p.propertyValue,
  );

  useEffect(() => {
    const token = propertiesQuery.data?.find(
      (p) => p.propertyName === 'helcimApiToken',
    )?.propertyValue;
    if (token) {
      setApiToken(token);
    }
  }, [propertiesQuery.data]);

  useEffect(() => {
    if (manageIntegration) {
      setDraftToken(apiToken);
    }
  }, [manageIntegration, apiToken]);

  const handleSaveToken = async () => {
    const result = await saveProperties([
      {
        clientId,
        propertyName: 'helcimApiToken',
        propertyValue: draftToken,
      },
    ]);

    if (isMutationSuccess(result)) {
      setApiToken(draftToken);
      enqueue({
        message: t('helcim_add_token_successfully'),
      });
      setManageIntegration(false);
    } else {
      enqueue({
        message: t('helcim_add_token_error'),
      });
    }
  };

  const handleDisconnect = async () => {
    const result = await saveProperties([
      {
        clientId,
        propertyName: 'helcimApiToken',
        propertyValue: '',
      },
    ]);

    if (isMutationSuccess(result)) {
      enqueue({
        message: t('integration_helcim_disconnect_successfully'),
      });
      setApiToken('');
      setDraftToken('');
      setManageIntegration(false);
    }
  };

  const handleClose = () => {
    setDraftToken(apiToken);
    setManageIntegration(false);
  };

  const registerUrl = `https://hub.helcim.com/signup/register?pt=c8a3b5eb69f0ad&cid=${clientId}`;

  return (
    <Card
      overrides={{
        Root: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        },
      }}
    >
      <StyledBody className="flex-1">
        <div className="flex items-start justify-between">
          <div className="flex h-20 w-20 items-center justify-center overflow-hidden rounded-md bg-[#7b5ce5]">
            <img src="/helcim-icon.png" alt="" />
          </div>
          {isConnected && (
            <Tag size="small" variant="light" kind="green" closeable={false}>
              {t('Connected')}
            </Tag>
          )}
        </div>
        <div className="mt-4">
          <div className="font-prompt">Helcim</div>
          <p className="h-16 text-sm text-gray-500">
            {t('integration_helcim_description')}
          </p>
        </div>
      </StyledBody>
      <StyledAction className="flex flex-wrap justify-end gap-4">
        {isConnected ? (
          <Button
            size="compact"
            kind="tertiary"
            onClick={() => setManageIntegration(true)}
          >
            {t('View Integration')}
          </Button>
        ) : (
          <>
            <Button
              kind="secondary"
              size="compact"
              startEnhancer={<Plus className="h-4 w-4" />}
              onClick={() => setManageIntegration(true)}
            >
              {t('Connect')}
            </Button>
            <Button
              size="compact"
              kind="secondary"
              onClick={() => window.open(registerUrl, '_blank')}
            >
              {t('Register')}
            </Button>
          </>
        )}

        <Modal onClose={handleClose} isOpen={manageIntegration} animate>
          <ModalHeader>
            {isConnected ? t('Manage Helcim Integration') : t('Connect Helcim')}
          </ModalHeader>
          <ModalBody className="grid gap-4 divide-y">
            <div className="flex flex-col space-y-2">
              <div>
                <div className="font-prompt font-semibold">API Token</div>
                <p className="text-sm text-gray-500">
                  {t('helcim_header_description')}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <div className="relative flex-1">
                  <Input
                    value={draftToken}
                    onChange={(e) => setDraftToken(e.target.value)}
                    placeholder={t('Enter your Helcim API token')}
                  />
                </div>
              </div>
            </div>

            {isConnected && (
              <div className="flex flex-col space-y-2 pt-4">
                <div>
                  <div className="font-prompt font-semibold">
                    {t('Disconnect Integration')}
                  </div>
                  <p className="text-sm text-gray-500">
                    {t('integration_helcim_disconnect_description')}
                  </p>
                </div>
                <Button
                  size="compact"
                  intent="danger"
                  className="ml-auto"
                  startEnhancer={<AlertCircle className="h-4 w-4" />}
                  isLoading={mutation.isLoading}
                  onClick={handleDisconnect}
                >
                  {t('Disconnect Now')}
                </Button>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <ModalButton kind="tertiary" onClick={handleClose}>
              {t('Cancel')}
            </ModalButton>
            <ModalButton
              onClick={handleSaveToken}
              isLoading={mutation.isLoading}
              disabled={!draftToken || draftToken === apiToken}
            >
              {t('Save')}
            </ModalButton>
          </ModalFooter>
        </Modal>
      </StyledAction>
    </Card>
  );
}

function QuickbooksIntegration() {
  const { t } = useLocale();
  const [manageIntegration, setManageIntegration] = useState(false);
  const { enqueue, dequeue } = useSnackbar();
  const query = useIsQuickbooksIntegratedQuery(undefined, {
    refetchOnFocus: true,
  });
  const [syncCustomers, mutation] = useSyncCustomersMutation();
  const [syncItems, itemsMutation] = useSyncItemsMutation();
  const isConnected = query.data?.isLoggedIn ?? false;
  const [disconnect, disconnectMutation] = useDisconnectQuickbooksMutation();
  const isLoading = [disconnectMutation, mutation].some((m) => m.isLoading);
  const isItemsLoading = [disconnectMutation, itemsMutation].some(
    (m) => m.isLoading,
  );

  return (
    <Card
      overrides={{
        Root: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        },
      }}
    >
      <StyledBody className="flex-1">
        <div className="flex items-start justify-between">
          <div className="flex h-20 w-20 items-center justify-center overflow-hidden rounded-md bg-[#2ca01c]">
            <img src="/quickbooks-icon.png" alt="" className="h-16 w-16" />
          </div>
          {isConnected && (
            <Tag size="small" variant="light" kind="green" closeable={false}>
              {t('Connected')}
            </Tag>
          )}
        </div>
        <div className="mt-4">
          <div className="font-prompt">Quickbooks</div>
          <p className="h-16 text-sm text-gray-500">
            {t('integration_quickbooks_description')}
          </p>
          {/* Sync your customers with Quickbooks. Just connect and Sync your
            customers. */}
        </div>
      </StyledBody>
      <StyledAction className="flex flex-wrap justify-end gap-4">
        {isConnected ? (
          <>
            <Button
              size="compact"
              kind="tertiary"
              onClick={() => setManageIntegration(true)}
            >
              {t('View Integration')}
            </Button>
            <Modal
              onClose={() => setManageIntegration(false)}
              isOpen={manageIntegration}
              animate
            >
              <ModalHeader>{t('Manage Integration')}</ModalHeader>
              <ModalBody className="grid gap-4 divide-y">
                <div className="flex flex-col space-y-2">
                  <div>
                    <div className="font-prompt font-semibold">
                      {t('Import Customers')}
                    </div>
                    <p className="text-sm text-gray-500">
                      {t('quickbooks_import_customers_description')}
                      {/* 'Import all your customers from Quickbooks to your the portal' */}
                    </p>
                  </div>
                  <Button
                    size="compact"
                    kind="secondary"
                    className="ml-auto"
                    isLoading={isLoading}
                    startEnhancer={<RefreshCw className="h-4 w-4" />}
                    onClick={async () => {
                      if (isLoading) return;

                      enqueue(
                        {
                          // message: 'Syncing Customers',
                          message: t('quickbooks_importing_customers'),
                          progress: true,
                        },
                        DURATION.infinite,
                      );

                      const result = await syncCustomers(
                        'sync-from-quickbooks',
                      );
                      if (isMutationSuccess(result)) {
                        dequeue();
                        enqueue({
                          // message: 'Synced customers successfully',
                          message: t(
                            'quickbooks_import_customers_successfully',
                          ),
                        });
                      } else {
                        dequeue();
                        enqueue({
                          // message: 'Failed to sync customers. Try Again.',
                          message: t('quickbooks_import_customers_error'),
                        });
                      }
                    }}
                  >
                    {t('Import Customers')}
                  </Button>
                </div>
                <div className="flex flex-col space-y-2 pt-4">
                  <div>
                    <div className="font-prompt font-semibold">
                      {t('Export Customers')}
                    </div>
                    <p className="text-sm text-gray-500">
                      {t('quickbooks_export_customers_description')}
                      {/* Export your customers in the portal to Quickbooks. */}
                    </p>
                  </div>
                  <Button
                    size="compact"
                    kind="secondary"
                    className="ml-auto"
                    isLoading={isLoading}
                    startEnhancer={<RefreshCw className="h-4 w-4" />}
                    onClick={async () => {
                      if (isLoading) return;

                      enqueue(
                        {
                          // message: 'Export Customers to Quickbooks',
                          message: t('quickbooks_exporting_customers'),
                          progress: true,
                        },
                        DURATION.infinite,
                      );

                      const result = await syncCustomers('sync-to-quickbooks');
                      if (isMutationSuccess(result)) {
                        dequeue();
                        enqueue({
                          // message: 'Export customers successfully',
                          message: t(
                            'quickbooks_export_customers_successfully',
                          ),
                        });
                      } else {
                        dequeue();
                        enqueue({
                          // message: 'Failed to Export customers. Try Again.',
                          message: t('quickbooks_export_customers_error'),
                        });
                      }
                    }}
                  >
                    {t('Export Customers')}
                  </Button>
                </div>

                <div className="flex flex-col space-y-2 pt-4">
                  <div>
                    <div className="font-prompt font-semibold">
                      {t('Import Pricebook')}
                    </div>
                    <p className="text-sm text-gray-500">
                      {/* Import all your Pricebook items from QuickBooks to the portal. */}
                      {t('quickbooks_import_pricebook_items_description')}
                    </p>
                  </div>
                  <Button
                    size="compact"
                    kind="secondary"
                    className="ml-auto"
                    isLoading={isItemsLoading}
                    startEnhancer={<RefreshCw className="h-4 w-4" />}
                    onClick={async () => {
                      if (isItemsLoading) return;

                      enqueue(
                        {
                          // message: 'Syncing Items',
                          message: t('quickbooks_importing_pricebook_items'),
                          progress: true,
                        },
                        DURATION.infinite,
                      );

                      await syncItems('sync-from-quickbooks-item');
                      dequeue();

                      enqueue({
                        // message: 'Synced Pricebook items successfully',
                        message: t(
                          'quickbooks_importing_pricebook_items_successfully',
                        ),
                      });
                    }}
                  >
                    {t('Import Pricebook')}
                  </Button>
                </div>

                <div className="flex flex-col space-y-2 pt-4">
                  <div>
                    <div className="font-prompt font-semibold">
                      {t('Export Pricebook')}
                    </div>
                    <p className="text-sm text-gray-500">
                      {/* Export your Pricebook items in the portal to Quickbooks. */}
                      {t('quickbooks_export_pricebook_items_description')}
                    </p>
                  </div>
                  <Button
                    size="compact"
                    kind="secondary"
                    className="ml-auto"
                    isLoading={isItemsLoading}
                    startEnhancer={<RefreshCw className="h-4 w-4" />}
                    onClick={async () => {
                      if (isItemsLoading) return;

                      enqueue(
                        {
                          // message: 'Exporting Pricebook Items to Quickbooks',
                          message: t('quickbooks_exporting_pricebook_items'),
                          progress: true,
                        },
                        DURATION.infinite,
                      );

                      await syncItems('sync-to-quickbooks-item');
                      dequeue();

                      enqueue({
                        // message: 'Export Pricebook Items successfully',
                        message: t(
                          'quickbooks_exporting_pricebook_items_successfully',
                        ),
                      });
                    }}
                  >
                    {t('Export Pricebook')}
                  </Button>
                </div>

                <div className="flex flex-col space-y-2 pt-4">
                  <div>
                    <div className="font-prompt font-semibold">
                      {t('Disconnect Integration')}
                    </div>
                    <p className="text-sm text-gray-500">
                      {t('quickbooks_disconnect_description')}
                      {/* This will disconnect your authentication with your
                      QuickBooks account. You can always connect again to import
                      or export your customers. */}
                    </p>
                  </div>
                  <Button
                    size="compact"
                    intent="danger"
                    className="ml-auto"
                    startEnhancer={<AlertCircle className="h-4 w-4" />}
                    isLoading={isLoading}
                    onClick={async () => {
                      await disconnect();
                      setManageIntegration(false);
                    }}
                  >
                    {t('Disconnect Now')}
                  </Button>
                </div>
              </ModalBody>
              <ModalFooter>
                <ModalButton
                  kind="tertiary"
                  onClick={() => setManageIntegration(false)}
                >
                  {t('Close')}
                </ModalButton>
              </ModalFooter>
            </Modal>
          </>
        ) : (
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target="_blank"
            rel="noreferrer"
            disabled={query.isFetching}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_PATH}quickbooks/login?auth=${
                  store.getState().auth.accessToken
                }&clientId=${store.getState().auth.userCredentials.clientId}`,
                'name',
                'width=600,height=800&toolbar=none',
              )
            }
            to="."
            $as={'a'}
            kind="secondary"
            size="compact"
            startEnhancer={<Plus className="h-4 w-4" />}
          >
            {t('Connect')}
          </Button>
        )}
      </StyledAction>
    </Card>
  );
}

function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>,
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev;

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(
              dirtyFields[key] as DirtyFields,
              values[key] as Values,
            )
          : values[key],
    };
  }, {});

  return dirtyValues;
}

const toDateTimeFormat = (value: unknown) =>
  z
    .preprocess((v) => {
      const value = z.string().catch('').parse(v);
      if (value.includes(':')) {
        return new Date(
          `${toPayloadDateFormat(new Date())}T${value}`,
        ).toISOString();
      } else if (value.search('-')) {
        return new Date(
          `${value}T${toPayloadTimeFormat(new Date())}:00`,
        ).toISOString();
      }
      return new Date().toISOString();
    }, z.string().datetime())
    .parse(value);

const workingHoursSchema = z.object({
  start: z.preprocess(
    (v) =>
      v
        ? `${toPayloadDateFormat(new Date())}T${v}:00`
        : set(new Date(), { hours: 8, minutes: 0, seconds: 0 }).toISOString(),
    z.coerce
      .date()
      .catch(set(new Date(), { hours: 8, minutes: 0, seconds: 0 })),
  ),
  end: z.preprocess(
    (v) =>
      !v
        ? set(new Date(), { hours: 17, minutes: 0, seconds: 0 }).toISOString()
        : `${toPayloadDateFormat(new Date())}T${v}:00`,
    z.coerce
      .date()
      .catch(set(new Date(), { hours: 17, minutes: 0, seconds: 0 })),
  ),
});

function SettingsTab() {
  const { t } = useLocale();
  const dispatch = useTypedDispatch();
  const clientId = useCurrentUser().data?.clientId ?? 0;
  const resellerId = useCurrentUser().data?.resellerId ?? 0;
  const { enqueue } = useSnackbar();
  const [uploadImage, uploadImageMutation] = useUploadImageMutation();
  // const [updateImage, updateImageMutation] = useUpdateImageMutation();
  const [saveProperties, mutation] = useSaveClientPropertiesMutation();
  const propertiesQuery = useClientPropertiesQuery();

  const mutations = [mutation, uploadImageMutation];
  const hasError = mutations.some((err) => err.isError);
  const firstError = mutations.find((err) => err.isError);

  return (
    <QueryResolver query={propertiesQuery}>
      {(properties) => {
        const workingHoursStart = properties.find(
          (p) => p.propertyName === 'workingHoursStart',
        )?.propertyValue;
        const workingHoursEnd = properties.find(
          (p) => p.propertyName === 'workingHoursEnd',
        )?.propertyValue;
        const workingHours = workingHoursSchema.parse({
          start: workingHoursStart,
          end: workingHoursEnd,
        });
        const is24Hours = isEqual(workingHours.end, startOfDay(new Date()));

        return (
          <>
            <MutationBanner
              show={hasError}
              title={t('Error')}
              message={
                firstError?.error ? (
                  // <div>{getErrorMessage(firstError.error)}</div>
                  // Failed to update client assets.
                  <div>{t('update_client_settings_error')}</div>
                ) : (
                  ''
                )
              }
            />
            <SettingsForm
              initialValue={{
                logo: {
                  imageId: Number(
                    properties.find((p) => p.propertyName === 'clientLogo')
                      ?.propertyValue ?? '0',
                  ),
                  file: [],
                },
                workingHours: {
                  start: workingHours.start.toISOString(),
                  end: workingHours.end.toISOString(),
                  is24HoursAvailable: is24Hours,
                },
                currency: z
                  .nativeEnum(CURRENCY_OPTION_VALUES)
                  .catch('USD')
                  .parse(
                    properties.find(
                      (p) => p.propertyName === 'currencyPreference',
                    )?.propertyValue,
                  ),
                driveTime: z.coerce
                  .number()
                  .parse(
                    properties.find((p) => p.propertyName === 'driveTime')
                      ?.propertyValue ?? 0,
                  ),
                reviewsUrl:
                  properties.find((p) => p.propertyName === 'reviewsUrl')
                    ?.propertyValue ?? '',
              }}
              onSubmit={async (data) => {
                let dataStoreId = data.logo.imageId;
                if (data.logo.file.length) {
                  const imageResult = await uploadImage({
                    file: data.logo.file[0],
                    resellerId,
                    clientId,
                    purpose: 'LogoLow',
                  });
                  if (isMutationSuccess(imageResult)) {
                    dataStoreId = imageResult.data.id;
                  } else {
                    enqueue({
                      // message: 'Failed to save changes. Try Again.'
                      message: t('update_client_settings_error'),
                    });
                  }
                }
                const result = await saveProperties([
                  {
                    clientId,
                    propertyName: 'workingHoursStart',
                    propertyValue: toPayloadTimeFormat(
                      new Date(data.workingHours.start),
                    ),
                  },
                  {
                    clientId,
                    propertyName: 'workingHoursEnd',
                    propertyValue: toPayloadTimeFormat(
                      new Date(data.workingHours.end),
                    ),
                  },
                  {
                    clientId,
                    propertyName: 'clientLogo',
                    propertyValue: `${dataStoreId}`,
                  },
                  {
                    clientId,
                    propertyName: 'currencyPreference',
                    propertyValue: data.currency,
                  },
                  {
                    clientId,
                    propertyName: 'driveTime',
                    propertyValue: `${data.driveTime}`,
                  },
                  {
                    clientId,
                    propertyName: 'reviewsUrl',
                    propertyValue: data.reviewsUrl,
                  },
                ]);
                if (isMutationSuccess(result)) {
                  dispatch(setPreferenceValue('currencyUsed', data.currency));
                  enqueue({
                    // message: 'Successfully saved changes.'
                    message: t('updated_client_settings_successfully'),
                  });
                }
              }}
            />
          </>
        );
      }}
    </QueryResolver>
  );
}

const useSettingsFormContext = () => useFormContext<FormFields>();
function SettingsForm({
  initialValue,
  onSubmit: onSubmitHandler,
}: {
  initialValue?: Partial<FormFields>;
  onSubmit: (
    data: FormFields,
    // dirtyFields: Partial<FormFields>,
  ) => PromiseLike<void>;
}) {
  const { t } = useLocale();
  const form = useForm<FormFields>({
    defaultValues: initialValue,
    resolver: zodResolver(settingsSchema),
  });
  const { control, handleSubmit, formState } = form;
  const onSubmit = handleSubmit(
    async (data) =>
      await onSubmitHandler(
        data,
        // , getDirtyValues(formState.dirtyFields, data)
      ),
  );

  return (
    <FormProvider {...form}>
      <form className="rounded-md bg-white font-prompt" onSubmit={onSubmit}>
        <StyledBody className="divide-y">
          <LogoSection control={control} />
          <WorkingHoursSection control={control} form={form} />
          <CurrencySection />
          <DriveTimeSection control={control} />
          <ReviewsUrlSection control={control} />
          <div className="flex justify-end space-x-3 pt-5">
            <Button type="button" className="px-5 py-3" kind="tertiary">
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              className="px-5 py-3"
              disabled={!formState.isDirty}
              isLoading={formState.isSubmitting}
            >
              {t('Save')}
            </Button>
          </div>
        </StyledBody>
      </form>
    </FormProvider>
  );
}

function ReviewsUrlSection({ control }: { control: Control<FormFields> }) {
  const { t } = useLocale();

  return (
    <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
      <div className="w-full max-w-xs">
        <h2 className="text-base font-semibold text-brand-primary">
          {t('Reviews URL')}
        </h2>
        <p className="text-sm text-gray-600">
          {/* Link to your external reviews page for reviews (e.g., Google Reviews). */}
          {t('reviews_url_settings_description')}
        </p>
      </div>
      <div className="max-w-md flex-1">
        <Controller
          control={control}
          name="reviewsUrl"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              placeholder="Enter your reviews URL"
            />
          )}
        />
      </div>
    </section>
  );
}

function DriveTimeSection({ control }: { control: Control<FormFields> }) {
  const { t } = useLocale();
  return (
    <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
      <div className="w-full max-w-xs">
        <h2 className="text-base font-semibold text-brand-primary">
          {t('Drive Time')}
        </h2>
        <p className="text-sm text-gray-600">
          {/* Set the amount of time for a technician to arrive to the next schedule. */}
          {t('drive_time_setting_description')}
        </p>
      </div>
      <div>
        <Controller
          control={control}
          name="driveTime"
          render={({ field }) => (
            <div className="flex items-center gap-4">
              <Button
                size="compact"
                kind="tertiary"
                shape="round"
                type="button"
                onClick={() => {
                  const currentValue = field.value || 0;
                  field.onChange(Math.max(currentValue - 15, 0));
                }}
              >
                <MinusCircle />
              </Button>
              <div className="w-32">
                <Input
                  value={field.value}
                  endEnhancer={<Description>Minutes</Description>}
                />
              </div>
              <Button
                size="compact"
                kind="tertiary"
                shape="round"
                type="button"
                onClick={() => {
                  const currentValue = field.value || 0;
                  field.onChange(currentValue + 15);
                }}
              >
                <PlusCircle />
              </Button>
            </div>
          )}
        />
      </div>
    </section>
  );
}

const CURRENCY_OPTIONS: Options = [
  { label: 'USD - United States Dollar', id: CURRENCY_OPTION_VALUES.USD },
  { label: 'MXN - Mexican Peso', id: CURRENCY_OPTION_VALUES.MXN },
  { label: 'CAD - Canadian Dollar', id: CURRENCY_OPTION_VALUES.CAD },
  { label: 'PEN - Peruvian Sol', id: CURRENCY_OPTION_VALUES.PEN },
  { label: 'COP - Colombian Dollar', id: CURRENCY_OPTION_VALUES.COP },
];
function CurrencySection() {
  const { t } = useLocale();
  const { control } = useSettingsFormContext();

  return (
    <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
      <div className="w-full max-w-xs">
        <h2 className="text-base font-semibold text-brand-primary">
          {t('Currency')}
        </h2>
        <p className="text-sm text-gray-600">
          {/* Choose the currency that you want to see prices in. You can change
          your currency preference at any time. */}
          {t('currency_setting_description')}
        </p>
      </div>
      <div className="min-w-[12rem]">
        <Controller
          control={control}
          name="currency"
          render={({ field }) => (
            <Select
              id={field.name}
              kind="primary"
              value={field.value ? [{ id: field.value }] : undefined}
              onChange={({ option }) => field.onChange(option?.id)}
              onBlur={field.onBlur}
              inputRef={field.ref}
              options={CURRENCY_OPTIONS}
              clearable={false}
            />
          )}
        />
      </div>
    </section>
  );
}

function WorkingHoursSection({
  form,
  control,
}: {
  control: Control<FormFields>;
  form: UseFormReturn<FormFields>;
}) {
  const { t } = useLocale();
  const toggled24Hours =
    useWatch({ control }).workingHours?.is24HoursAvailable ?? false;
  const { setValue } = form;

  return (
    <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
      <div className="max-w-xs">
        <h2 className="text-base font-semibold text-brand-primary">
          {t('Working Hours')}
        </h2>
        <p className="text-sm text-gray-600">
          {/* Set your working hours to be used for scheduling. 
          They will be used to suggest optimal schedule slots. */}
          {t('working_hours_description')}
        </p>
      </div>
      <div className="max-w-md flex-1">
        <fieldset className="relative max-w-xl flex-1">
          <legend className="sr-only">Working Hours</legend>
          <div className="flex w-full items-end space-x-4">
            <FormController
              control={control}
              name="workingHours.start"
              label={t('Start time')}
              overrides={{
                Root: { props: { className: 'w-full' } },
                ControlContainer: { props: { className: 'mb-0' } },
              }}
              defaultValue={set(new Date(), {
                hours: 8,
                minutes: 0,
                seconds: 0,
              }).toISOString()}
            >
              {({ field }) => (
                <TimePicker
                  disabled={toggled24Hours}
                  step={1800}
                  value={new Date(field.value)}
                  onChange={(date) => {
                    field.value = date ? date.toISOString() : '';
                    field.onChange(field.value);
                  }}
                />
              )}
            </FormController>
            <span aria-hidden className="pb-3 text-brand-primary-500">
              <ArrowRight />
            </span>
            <FormController
              control={control}
              name="workingHours.end"
              label={t('End time')}
              overrides={{
                Root: { props: { className: 'w-full' } },
                ControlContainer: { props: { className: 'mb-0' } },
              }}
              defaultValue={set(new Date(), {
                hours: 17,
                minutes: 0,
                seconds: 0,
              }).toISOString()}
            >
              {({ field }) => (
                <TimePicker
                  disabled={toggled24Hours}
                  step={1800}
                  value={new Date(field.value)}
                  onChange={(date) => {
                    field.value = date ? date.toISOString() : '';
                    field.onChange(field.value);
                  }}
                />
              )}
            </FormController>
          </div>
          <RHFErrorMessageValue control={control} field="workingHours" />
        </fieldset>
        <HeadlessSwitchField
          control={form.control}
          field="workingHours.is24HoursAvailable"
          defaultSelected={false}
          className="flex items-center gap-4 py-2"
          onChange={(checked) => {
            if (checked) {
              setValue(
                'workingHours.start',
                set(new Date(), {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                }).toISOString(),
              );
              setValue(
                'workingHours.end',
                set(new Date(), {
                  hours: 24,
                  minutes: 0,
                  seconds: 0,
                }).toISOString(),
              );
            }
          }}
        >
          <LabeledSwitch labels={{ on: t('Yes'), off: t('No') }} />
          <Label>{t('Available 24 hours?')}</Label>
        </HeadlessSwitchField>
      </div>
    </section>
  );
}
function LogoSection({ control }: { control: Control<FormFields> }) {
  const value = useWatch({ control }).logo;
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useLocale();

  return (
    <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
      <div className="max-w-xs">
        <h2 className="text-base font-semibold text-brand-primary">
          {t('Business Logo')}
        </h2>
        <p className="text-sm text-gray-600">
          {t('client_logo_description')}
          {/* Update your business logo to be used for reports and emails. */}
        </p>
      </div>
      <div className="flex min-w-[20rem] max-w-xl flex-1 gap-4">
        <div className="h-28 w-28 shrink-0 overflow-hidden rounded bg-brand-primary-50">
          {value?.imageId ? (
            <Image
              imageId={Number(value.imageId)}
              className="h-full w-full object-contain object-center"
            />
          ) : null}
        </div>
        <div className="relative h-full">
          <div className="flex h-full items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-5">
            <Controller
              control={control}
              name="logo.file"
              render={({ field }) => (
                <div
                  className={clsx({
                    hidden: field.value.length !== 0,
                    'text-center': field.value.length === 0,
                  })}
                >
                  {/* <UploadCloud className="mx-auto h-7 w-7 text-gray-500" /> */}
                  <div className="text-center text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-logo-input"
                      className="relative cursor-pointer rounded-md bg-white text-center font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 hover:underline"
                    >
                      <span>{t('Click to upload')}</span>
                      <input
                        ref={inputRef}
                        id="file-logo-input"
                        type="file"
                        disabled={!!field.value.length}
                        className="absolute inset-0 opacity-0"
                        accept="image/x-png,image/jpeg,image/jpg"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          field.onChange(file ? [file] : []);
                        }}
                      />
                    </label>
                    {/* <p className="pl-1"> or drag and drop</p> */}
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    JPG, PNG, or JPEG (max. 800x800px)
                  </p>
                </div>
              )}
            />
            <Controller
              control={control}
              name="logo.file"
              render={({ field }) =>
                field.value.length ? (
                  <div className="flex items-center space-x-4 rounded-md bg-gray-50 p-4">
                    <div className="w-32 shrink-0">
                      <ImagePreview file={field.value[0]} />
                    </div>
                    <div className="flex-1">
                      <span className="text-base font-semibold text-gray-500">
                        {field.value[0].name}
                      </span>
                      <div className="text-sm text-gray-500">
                        {field.value[0].size / 1000}KB
                      </div>
                    </div>
                    <div>
                      <Button
                        size="mini"
                        kind="tertiary"
                        intent="danger"
                        shape="square"
                        onClick={() => {
                          field.onChange(undefined);
                          if (inputRef.current) {
                            inputRef.current.value = '';
                          }
                        }}
                      >
                        <X className="h-3 w-3" />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div />
                )
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function ImagePreview({ file }: { file?: File }) {
  const [src, setSrc] = useState<string | null | undefined>('');

  useEffect(() => {
    if (file?.name) {
      const reader = new FileReader();
      reader.addEventListener('load', (e) => {
        const result = e.target?.result?.toString();
        if (result) {
          setSrc(result);
        }
      });
      reader.readAsDataURL(file);
    }
  }, [file]);

  if (!src) {
    return null;
  }
  return <img src={src} alt="Preview" className="h-full w-full object-cover" />;
}

function RHFErrorMessageValue<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  control,
  field,
  children,
}: {
  control: Control<T>;
  field: TName;
  children?: (message: string) => React.ReactNode;
}) {
  const { t, i18n } = useLocale();
  const { errors } = useFormState({ control, name: field, exact: true });
  const error = getFieldError(errors, field, '');

  if (!error) {
    return null;
  }
  const { message: messageFromRegister } = error;
  const errorMessage = messageFromRegister;
  const keyedMessage = errorMessage ? `${errorMessage}` : '';

  /**
   * The zod `message` may be passed a translation key (ex:errors.undefined) to handle custom
   * error message without `refine`.
   */
  const keyedMessageExist = keyedMessage
    ? i18n.exists(keyedMessage, {
        ns: 'zod',
      })
    : false;

  const translatedMessage = keyedMessageExist
    ? t(keyedMessage, { ns: 'zod' })
    : // This should already contain the translated message if no `keyed` message
    errorMessage
    ? errorMessage
    : t('default_validation_message', {
        ns: 'zod',
      });

  if (!children) {
    return <StyledCaption $error>{translatedMessage}</StyledCaption>;
  }
  return <>{children(translatedMessage)}</>;
}

type SettingsTabProps = {
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
};

function SmtpTab({ setActiveTab }: SettingsTabProps) {
  const { t } = useLocale();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const clientId = useCurrentUser().data?.clientId ?? 0;
  const [editClient, mutation] = useEditClientMutation();
  const query = useClientQuery(clientId);
  const resellerId = useTypedSelector((s) => s.auth.userCredentials.resellerId);

  return (
    <div>
      <div>
        <MutationErrorBanner
          error={mutation.error}
          show={mutation.isError}
          focusOnError
        />
        <QueryResolver query={query}>
          {(resellerClient) => (
            <SmtpForm
              id="smtp-form"
              initialValues={{
                accountName: resellerClient.businessName,
                accountRepresentative: resellerClient.accountRep,
                leaderName: resellerClient.mainContactName,
                leaderEmail: resellerClient.mainContactEmail,
                leaderPhoneNumber: resellerClient.mainContactPhone,
                leaderPassword: 'password',
                firstname: resellerClient.firstName,
                lastname: resellerClient.lastName,
                billing: {
                  address: resellerClient.address,
                  emailAddress: resellerClient.billingContactEmail,
                  phone: resellerClient.billingContactPhone,
                  name: resellerClient.billingContactName,
                },
                smtp: resellerClient.smtpCredentials,
                timezone: resellerClient.timeZone,
              }}
              onSubmit={async (data) => {
                const result = await editClient({
                  resellerId,
                  id: resellerClient.id,
                  businessName: data.accountName,
                  mainContactName: data.leaderName,
                  mainContactEmail: data.leaderEmail,
                  mainContactPhone: data.leaderPhoneNumber,
                  firstName: data.firstname,
                  lastName: data.lastname,
                  // password: data.leaderPassword,
                  address: {
                    ...data.billing.address,
                    street2: data.billing.address.street2 ?? '',
                    timeZone: data.billing.address.timeZone ?? '',
                  },
                  billingContactName: data.billing.name,
                  billingContactEmail: data.billing.emailAddress,
                  billingContactPhone: data.billing.phone,
                  accountRep: data.accountRepresentative,
                  smtpCredentials: data.smtp,
                  timeZone: data.timezone,
                  masterSkills: resellerClient.masterSkills,
                  isEnabled: resellerClient.isEnabled,
                });

                if (isMutationSuccess(result)) {
                  snackbar.enqueue({ message: t('SMTP Information Updated') });
                  setActiveTab('SMTPInformation');
                  navigate('/fs-admin/settings');
                }
              }}
            />
          )}
        </QueryResolver>
      </div>
      <div className="flex items-end justify-end pb-5">
        <Button
          startEnhancer={<Save className="h-4 w-4" />}
          form="smtp-form"
          type="submit"
          className="w-full max-w-[10rem]"
          isLoading={mutation.isLoading}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
}

const nonEmptyString = z.string().trim().min(1, { message: 'Required' });
const addressSchema = z.object({
  street: nonEmptyString,
  street2: z.string().nullable().optional().default(''),
  city: nonEmptyString,
  state: nonEmptyString,
  postalCode: nonEmptyString,
  country: nonEmptyString,
  lat: z.number().optional().default(0),
  lng: z.number().optional().default(0),
  timeZone: z.string().nullable().optional().default(''),
});

export const schema = z.object({
  accountName: nonEmptyString,
  leaderName: nonEmptyString,
  leaderEmail: nonEmptyString.email(),
  leaderPhoneNumber: nonEmptyString,
  leaderPassword: nonEmptyString,
  firstname: nonEmptyString,
  lastname: nonEmptyString,
  timezone: z.string(),
  billing: z.object({
    address: addressSchema,
    name: nonEmptyString,
    emailAddress: nonEmptyString.email(),
    phone: nonEmptyString,
  }),
  accountRepresentative: z.string(),
  smtp: z.object({
    host: nonEmptyString,
    port: z.coerce.number({ invalid_type_error: 'Required' }).min(1, {
      message: 'Required',
    }),
    user: nonEmptyString,
    password: nonEmptyString,
    from: nonEmptyString.email(),
    tls: z
      .union([z.literal('Enabled'), z.literal('Disabled')])
      .optional()
      .default('Disabled'),
    ssl: z
      .union([z.literal('Enabled'), z.literal('Disabled')])
      .optional()
      .default('Disabled'),
    // authMethod: z.string().optional().default('None'),
    // timeoutMS: z
    //   .preprocess(Number, z.number())
    //   .nullable()
    //   .optional()
    //   .default(0),
    // sendPartial: z.boolean().nullable().optional().default(true),
    // to: z.array(nonEmptyString.email()).nullable().optional().default([]),
    // cc: z.array(nonEmptyString.email()).nullable().optional().default([]),
    // bcc: z.array(nonEmptyString.email()).nullable().default([]),
    retryCount: z
      .preprocess(Number, z.number())
      .nullable()
      .optional()
      .default(0),
    debug: z.boolean().nullable().optional().default(true),
  }),
});

export type SmtpFormFields = z.infer<typeof schema>;

function SmtpForm({
  id = 'smtp-form',
  initialValues,
  onSubmit: onSubmitHandler,
}: {
  id?: string;
  initialValues?: Partial<SmtpFormFields>;
  onSubmit: (data: SmtpFormFields) => void;
}) {
  const { t } = useLocale();

  const userEmail = useCurrentUser().data?.email;

  const { control, handleSubmit } = useForm<SmtpFormFields>({
    defaultValues: initialValues,
    resolver: zodResolver(schema),
  });

  const snackbar = useSnackbar();
  const [triggerTestEmail] = useLazyTestSmtpEmailQuery();

  const testSmtpEmail = useCallback(async () => {
    try {
      if (!userEmail) {
        snackbar.enqueue({
          message: t('test_smtp_email_no_user_email'),
        });
        return;
      }

      const result = await triggerTestEmail({ email: userEmail });

      if (result.data) {
        snackbar.enqueue({
          message: `${result.data.email || userEmail} ${t(
            'test_smtp_email_receive_description',
          )}`,
        });
      } else {
        snackbar.enqueue({
          message: t('test_smtp_email_sent_description'),
        });
      }
    } catch (error) {
      snackbar.enqueue({
        message: t('test_smtp_email_sent_description'),
      });
    }
  }, [triggerTestEmail, snackbar, t, userEmail]);

  const onSubmit = handleSubmit(onSubmitHandler);

  return (
    <form onSubmit={onSubmit} id={id} className="grid grid-cols-2 gap-x-4">
      <fieldset className="col-span-full mt-7 space-y-4">
        <legend className="text-xl font-semibold text-black">
          {t('SMTP Provider information')}
        </legend>
        <div className="grid grid-cols-2 gap-x-4">
          <FormController control={control} name="smtp.host" label="Host Name">
            {({ field }) => <FormInput {...field} />}
          </FormController>
          <FormController
            control={control}
            name="smtp.port"
            label="Port Number"
          >
            {({ field }) => <FormInput {...field} />}
          </FormController>
          <FormController control={control} name="smtp.user" label="Username">
            {({ field }) => <FormInput {...field} />}
          </FormController>
          <FormController
            control={control}
            name="smtp.password"
            label="Password"
          >
            {({ field }) => <FormInput {...field} type="password" />}
          </FormController>
          <FormController
            control={control}
            name="smtp.from"
            label="'From' Email Address"
            overrides={{
              Root: { props: { className: 'col-span-full w-1/2' } },
            }}
          >
            {({ field }) => <FormInput {...field} />}
          </FormController>
          <div className="grid grid-cols-2 gap-x-4">
            <FormController
              control={control}
              name="smtp.tls"
              label="TLS"
              defaultValue={'Disabled'}
              overrides={{
                ControlContainer: {
                  props: {
                    className: 'flex flex-col items-start',
                  },
                },
              }}
            >
              {({ field }) => (
                <Checkbox
                  {...field}
                  value={''}
                  checked={field.value === 'Enabled' ? true : false}
                  checkmarkType="toggle"
                  onChange={(e) =>
                    field.onChange(e.target.checked ? 'Enabled' : 'Disabled')
                  }
                  overrides={
                    {
                      ToggleTrack: {
                        props: {
                          className: 'w-24 h-6',
                        },
                        style: (
                          props: CheckboxSharedProps & { $theme: typeof theme },
                        ) => ({
                          backgroundColor: props.$checked
                            ? props.$theme.colors.mono400
                            : props.$theme.colors.primary,
                        }),
                      },
                      Toggle: {
                        component: (props: CheckboxSharedProps) => (
                          <StyledToggle
                            {...props}
                            className={clsx(
                              'flex h-6 w-20 items-center justify-center rounded px-2 text-sm',
                              props.$checked
                                ? 'translate-x-4 text-white'
                                : 'text-gray-700',
                            )}
                          >
                            {props.$checked ? 'Enabled' : 'Disabled'}
                          </StyledToggle>
                        ),
                      },
                    } as CheckboxOverrides
                  }
                />
              )}
            </FormController>
            <FormController
              control={control}
              name="smtp.ssl"
              label="SSL"
              defaultValue={'Disabled'}
              overrides={{
                ControlContainer: {
                  props: {
                    className: 'flex flex-col items-start',
                  },
                },
              }}
            >
              {({ field }) => (
                <Checkbox
                  checkmarkType="toggle"
                  {...field}
                  value={''}
                  checked={field.value === 'Enabled' ? true : false}
                  onChange={(e) =>
                    field.onChange(e.target.checked ? 'Enabled' : 'Disabled')
                  }
                  overrides={
                    {
                      ToggleTrack: {
                        props: {
                          className: 'w-24 h-6',
                        },
                        style: (
                          props: CheckboxSharedProps & { $theme: typeof theme },
                        ) => ({
                          backgroundColor: props.$checked
                            ? props.$theme.colors.mono400
                            : props.$theme.colors.primary,
                        }),
                      },
                      Toggle: {
                        component: (props: CheckboxSharedProps) => (
                          <StyledToggle
                            {...props}
                            className={clsx(
                              'flex h-6 w-20 items-center justify-center rounded px-2 text-sm',
                              props.$checked
                                ? 'translate-x-4 text-white'
                                : 'text-gray-700',
                            )}
                          >
                            {props.$checked ? 'Enabled' : 'Disabled'}
                          </StyledToggle>
                        ),
                      },
                    } as CheckboxOverrides
                  }
                />
              )}
            </FormController>
          </div>
          <div className="mt-8">
            <Button
              type="button"
              kind="secondary"
              size="compact"
              onClick={testSmtpEmail}
              startEnhancer={<Send className="h-4 w-4" />}
            >
              {t('Test SMTP Email')}
            </Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

function MutationErrorBanner({
  error,
  focusOnError,
  show = true,
}: {
  error: FetchBaseQueryError | SerializedError | undefined;
  focusOnError?: boolean;
  show?: boolean;
}) {
  const { t } = useLocale();
  const bannerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (show && focusOnError) {
      bannerRef.current?.focus();
    }
  }, [show, focusOnError]);

  if (!show) return null;

  return (
    <div className="mb-4 p-4">
      <div
        className="flex rounded-md bg-red-50 p-5"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        role="alert"
        ref={bannerRef}
      >
        <div className="flex-shrink-0">
          <XCircle className="h-6 w-6 text-red-500" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-semibold text-red-800">
            {/* Failed to update SMTP Information. */}
            {t('smtp_information_settings_update_error')}
          </h3>
        </div>
      </div>
    </div>
  );
}

function NotificationsTab() {
  const { t } = useLocale();
  const clientId = useCurrentUser().data?.clientId ?? 0;
  const { enqueue } = useSnackbar();
  const [saveProperties, mutation] = useSaveClientPropertiesMutation();
  const propertiesQuery = useClientPropertiesQuery();

  const hasError = mutation.isError;

  return (
    <QueryResolver query={propertiesQuery}>
      {(properties) => {
        // All send toggle settings would default to true
        const getSendMessageValue = (propertyName: string) => {
          const property = properties.find(
            (p) => p.propertyName === propertyName,
          );
          return property?.propertyValue
            ? property.propertyValue === 'true'
            : true;
        };

        // Email Notifications Settings
        const emailScheduleConfirmationMessage =
          properties.find(
            (p) => p.propertyName === 'emailScheduleConfirmationMessage',
          )?.propertyValue === 'true';
        const emailJobCompleteMessage =
          properties.find((p) => p.propertyName === 'emailJobCompleteMessage')
            ?.propertyValue === 'true';
        const emailNextDayReminderMessage =
          properties.find(
            (p) => p.propertyName === 'emailNextDayReminderMessage',
          )?.propertyValue === 'true';
        const emailOnTheWayMessage =
          properties.find((p) => p.propertyName === 'emailOnTheWayMessage')
            ?.propertyValue === 'true';
        const emailDeliveredMessage =
          properties.find((p) => p.propertyName === 'emailDeliveredMessage')
            ?.propertyValue === 'true';

        // Turn Notifications On/Off Settings
        const sendScheduleConfirmationMessage = getSendMessageValue(
          'sendScheduleConfirmationMessage',
        );
        const sendOnTheWayMessage = getSendMessageValue('sendOnTheWayMessage');
        const sendNextDayReminderMessage = getSendMessageValue(
          'sendNextDayReminderMessage',
        );
        const sendJobCompleteMessage = getSendMessageValue(
          'sendJobCompleteMessage',
        );
        const sendDeliveredMessage = getSendMessageValue(
          'sendDeliveredMessage',
        );

        return (
          <>
            <MutationBanner
              show={hasError}
              title={t('Error')}
              message={
                mutation.error ? (
                  <div>{t('update_client_settings_error')}</div>
                ) : (
                  ''
                )
              }
            />
            <NotificationsForm
              initialValue={{
                // Email Notification Setttings
                emailScheduleConfirmationMessage,
                emailJobCompleteMessage,
                emailNextDayReminderMessage,
                emailOnTheWayMessage,
                emailDeliveredMessage,
                // Turn Notifications On/Off Settings
                sendScheduleConfirmationMessage,
                sendOnTheWayMessage,
                sendNextDayReminderMessage,
                sendJobCompleteMessage,
                sendDeliveredMessage,
              }}
              onSubmit={async (data) => {
                const result = await saveProperties([
                  // Email Notification Setttings
                  {
                    clientId,
                    propertyName: 'emailScheduleConfirmationMessage',
                    propertyValue:
                      data.emailScheduleConfirmationMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'emailJobCompleteMessage',
                    propertyValue: data.emailJobCompleteMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'emailNextDayReminderMessage',
                    propertyValue: data.emailNextDayReminderMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'emailOnTheWayMessage',
                    propertyValue: data.emailOnTheWayMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'emailDeliveredMessage',
                    propertyValue: data.emailDeliveredMessage.toString(),
                  },
                  // Turn Notifications On/Off Settings
                  {
                    clientId,
                    propertyName: 'sendScheduleConfirmationMessage',
                    propertyValue:
                      data.sendScheduleConfirmationMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'sendOnTheWayMessage',
                    propertyValue: data.sendOnTheWayMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'sendNextDayReminderMessage',
                    propertyValue: data.sendNextDayReminderMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'sendJobCompleteMessage',
                    propertyValue: data.sendJobCompleteMessage.toString(),
                  },
                  {
                    clientId,
                    propertyName: 'sendDeliveredMessage',
                    propertyValue: data.sendDeliveredMessage.toString(),
                  },
                ]);
                if (isMutationSuccess(result)) {
                  enqueue({
                    message: t('updated_client_settings_successfully'),
                  });
                }
              }}
            />
          </>
        );
      }}
    </QueryResolver>
  );
}

const notificationsSchema = z.object({
  // Email Notification Setttings
  emailScheduleConfirmationMessage: z.boolean(),
  emailJobCompleteMessage: z.boolean(),
  emailNextDayReminderMessage: z.boolean(),
  emailOnTheWayMessage: z.boolean(),
  emailDeliveredMessage: z.boolean(),
  // Turn Notifications On/Off Settings Schema
  sendScheduleConfirmationMessage: z.coerce.boolean().catch(true),
  sendOnTheWayMessage: z.coerce.boolean().catch(true),
  sendNextDayReminderMessage: z.coerce.boolean().catch(true),
  sendJobCompleteMessage: z.coerce.boolean().catch(true),
  sendDeliveredMessage: z.coerce.boolean().catch(true),
});

type NotificationsFormFields = z.infer<typeof notificationsSchema>;
function NotificationsForm({
  initialValue,
  onSubmit: onSubmitHandler,
}: {
  initialValue?: Partial<NotificationsFormFields>;
  onSubmit: (data: NotificationsFormFields) => Promise<void>;
}) {
  const { t } = useLocale();
  const form = useForm<NotificationsFormFields>({
    defaultValues: {
      sendScheduleConfirmationMessage: true,
      sendOnTheWayMessage: true,
      sendNextDayReminderMessage: true,
      sendJobCompleteMessage: true,
      sendDeliveredMessage: true,
      emailScheduleConfirmationMessage: false,
      emailJobCompleteMessage: false,
      emailNextDayReminderMessage: false,
      emailOnTheWayMessage: false,
      emailDeliveredMessage: false,
      ...initialValue,
    },
    resolver: zodResolver(notificationsSchema),
  });
  const { control, handleSubmit, formState } = form;
  const onSubmit = handleSubmit(async (data) => await onSubmitHandler(data));

  return (
    <FormProvider {...form}>
      <form className="rounded-md bg-white font-prompt" onSubmit={onSubmit}>
        <StyledBody className="divide-y">
          <div className="flex space-x-40">
            <div className="space-y-4 pb-5">
              <legend className="mt-2 text-xl font-semibold text-black">
                {t('Turn Notifications On/Off Settings')}
              </legend>
              {/* Schedule Confirmation */}
              <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
                <div className="w-full max-w-xs">
                  <h2 className="text-base font-semibold text-brand-primary">
                    {t('Schedule Confirmation')}
                  </h2>
                  <p className="text-sm text-gray-600">
                    {t('send_confirmation_description')}
                  </p>
                </div>
                <div className="max-w-md flex-1">
                  <FormController
                    control={control}
                    name="sendScheduleConfirmationMessage"
                    overrides={{
                      Root: { props: { className: 'w-full' } },
                      ControlContainer: { props: { className: 'mb-0' } },
                    }}
                  >
                    {({ field }) => (
                      <HeadlessSwitchField
                        control={control}
                        field="sendScheduleConfirmationMessage"
                        defaultSelected={field.value}
                        className="flex items-center gap-4 py-2"
                        onChange={(checked) => field.onChange(checked)}
                      >
                        <LabeledSwitch
                          labels={{ on: t('Yes'), off: t('No') }}
                        />
                        <Label>{t('Send Schedule Confirmation?')}</Label>
                      </HeadlessSwitchField>
                    )}
                  </FormController>
                </div>
              </section>
              {/* On The Way */}
              <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
                <div className="w-full max-w-xs">
                  <h2 className="text-base font-semibold text-brand-primary">
                    {t('On The Way')}
                  </h2>
                  <p className="text-sm text-gray-600">
                    {t('send_on_the_way_description')}
                  </p>
                </div>
                <div className="max-w-md flex-1">
                  <FormController
                    control={control}
                    name="sendOnTheWayMessage"
                    overrides={{
                      Root: { props: { className: 'w-full' } },
                      ControlContainer: { props: { className: 'mb-0' } },
                    }}
                  >
                    {({ field }) => (
                      <HeadlessSwitchField
                        control={control}
                        field="sendOnTheWayMessage"
                        defaultSelected={field.value}
                        className="flex items-center gap-4 py-2"
                        onChange={(checked) => field.onChange(checked)}
                      >
                        <LabeledSwitch
                          labels={{ on: t('Yes'), off: t('No') }}
                        />
                        <Label>{t('Send On The Way?')}</Label>
                      </HeadlessSwitchField>
                    )}
                  </FormController>
                </div>
              </section>
              {/* Next Day Schedule Reminder */}
              <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
                <div className="w-full max-w-xs">
                  <h2 className="text-base font-semibold text-brand-primary">
                    {t('Next Day Schedule Reminder')}
                  </h2>
                  <p className="text-sm text-gray-600">
                    {t('send_next_day_schedule_reminder_description')}
                  </p>
                </div>
                <div className="max-w-md flex-1">
                  <FormController
                    control={control}
                    name="sendNextDayReminderMessage"
                    overrides={{
                      Root: { props: { className: 'w-full' } },
                      ControlContainer: { props: { className: 'mb-0' } },
                    }}
                  >
                    {({ field }) => (
                      <HeadlessSwitchField
                        control={control}
                        field="sendNextDayReminderMessage"
                        defaultSelected={field.value}
                        className="flex items-center gap-4 py-2"
                        onChange={(checked) => field.onChange(checked)}
                      >
                        <LabeledSwitch
                          labels={{ on: t('Yes'), off: t('No') }}
                        />
                        <Label>{t('Send Next Day Schedule Reminder?')}</Label>
                      </HeadlessSwitchField>
                    )}
                  </FormController>
                </div>
              </section>
              {/* Job Done */}
              <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
                <div className="w-full max-w-xs">
                  <h2 className="text-base font-semibold text-brand-primary">
                    {t('Job Done')}
                  </h2>
                  <p className="text-sm text-gray-600">
                    {t('send_job_done_description')}
                  </p>
                </div>
                <div className="max-w-md flex-1">
                  <FormController
                    control={control}
                    name="sendJobCompleteMessage"
                    overrides={{
                      Root: { props: { className: 'w-full' } },
                      ControlContainer: { props: { className: 'mb-0' } },
                    }}
                  >
                    {({ field }) => (
                      <HeadlessSwitchField
                        control={control}
                        field="sendJobCompleteMessage"
                        defaultSelected={field.value}
                        className="flex items-center gap-4 py-2"
                        onChange={(checked) => field.onChange(checked)}
                      >
                        <LabeledSwitch
                          labels={{ on: t('Yes'), off: t('No') }}
                        />
                        <Label>{t('Send Job Done?')}</Label>
                      </HeadlessSwitchField>
                    )}
                  </FormController>
                </div>
              </section>
              {/* Delivered Message */}
              <section className="flex shrink-0 flex-wrap gap-x-28 gap-y-7 px-6 py-4">
                <div className="w-full max-w-xs">
                  <h2 className="text-base font-semibold text-brand-primary">
                    {t('Delivered Message')}
                  </h2>
                  <p className="text-sm text-gray-600">
                    {t('delivered_message_description')}
                  </p>
                </div>
                <div className="max-w-md flex-1">
                  <FormController
                    control={control}
                    name="sendDeliveredMessage"
                    overrides={{
                      Root: { props: { className: 'w-full' } },
                      ControlContainer: { props: { className: 'mb-0' } },
                    }}
                  >
                    {({ field }) => (
                      <HeadlessSwitchField
                        control={control}
                        field="sendDeliveredMessage"
                        defaultSelected={field.value}
                        className="flex items-center gap-4 py-2"
                        onChange={(checked) => field.onChange(checked)}
                      >
                        <LabeledSwitch
                          labels={{ on: t('Yes'), off: t('No') }}
                        />
                        <Label>{t('Send Delivered Message?')}</Label>
                      </HeadlessSwitchField>
                    )}
                  </FormController>
                </div>
              </section>
            </div>
            <div className="space-y-4 pb-5">
              <legend className="mt-2 text-xl font-semibold text-black">
                {t('Email Notification Settings')}
              </legend>
              <div className="flex flex-col">
                <h2 className="mb-4 ml-2 text-base font-semibold text-brand-primary">
                  {t('Send as Email')}
                </h2>

                <div className="mb-4 ml-12 flex items-center justify-start space-x-4">
                  <Controller
                    control={control}
                    name="emailScheduleConfirmationMessage"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <h1 className="text-base text-black">
                    {t('Schedule Confirmation')}
                  </h1>
                </div>

                <div className="mb-4 ml-12 flex items-center justify-start space-x-4">
                  <Controller
                    control={control}
                    name="emailOnTheWayMessage"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <h1 className="text-base text-black">{t('On The Way')}</h1>
                </div>

                <div className="mb-4 ml-12 flex items-center justify-start space-x-4">
                  <Controller
                    control={control}
                    name="emailNextDayReminderMessage"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <h1 className="text-base text-black">
                    {t('Next Day Schedule Reminder')}
                  </h1>
                </div>

                <div className="mb-4 ml-12 flex items-center justify-start space-x-4">
                  <Controller
                    control={control}
                    name="emailJobCompleteMessage"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <h1 className="text-base text-black">{t('Job Done')}</h1>
                </div>

                <div className="mb-4 ml-12 flex items-center justify-start space-x-4">
                  <Controller
                    control={control}
                    name="emailDeliveredMessage"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <h1 className="text-base text-black">
                    {t('Delivered Message')}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-3 pt-5">
            <Button type="button" className="px-5 py-3" kind="tertiary">
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              className="px-5 py-3"
              disabled={!formState.isDirty}
              isLoading={formState.isSubmitting}
            >
              {t('Save')}
            </Button>
          </div>
        </StyledBody>
      </form>
    </FormProvider>
  );
}

function ScheduleSlotColorsTab() {
  const { t } = useLocale();
  const clientId = useCurrentUser().data?.clientId ?? 0;
  const { enqueue } = useSnackbar();
  const [saveProperties, mutation] = useSaveClientPropertiesMutation();
  const propertiesQuery = useClientPropertiesQuery();

  const [colors, setColors] = useState({
    inProgressColor: 'Blue',
    completedColor: 'Green',
    aheadColor: 'Gold',
    behindColor: 'Red',
  });

  useEffect(() => {
    if (propertiesQuery.data) {
      const data = propertiesQuery.data as ClientProperty[];
      const getPropertyValue = (name: string) => {
        const property = data.find((p) => p.propertyName === name);
        // If propertyValue is empty string or null, return 'Default'
        return property?.propertyValue ? property.propertyValue : 'Default';
      };

      setColors({
        inProgressColor: getPropertyValue('scheduleSlotInProgressColor'),
        completedColor: getPropertyValue('scheduleSlotCompletedColor'),
        aheadColor: getPropertyValue('scheduleSlotAheadColor'),
        behindColor: getPropertyValue('scheduleSlotBehindColor'),
      });
    }
  }, [propertiesQuery.data]);

  const handleColorChange = useCallback((colorName: string, value: string) => {
    setColors((prev) => ({ ...prev, [colorName]: value }));
  }, []);

  const handleResetColors = useCallback(() => {
    setColors({
      inProgressColor: 'Default',
      completedColor: 'Default',
      aheadColor: 'Default',
      behindColor: 'Default',
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      const result = await saveProperties([
        {
          clientId,
          propertyName: 'scheduleSlotInProgressColor',
          propertyValue:
            colors.inProgressColor === 'Default' ? '' : colors.inProgressColor,
        },
        {
          clientId,
          propertyName: 'scheduleSlotCompletedColor',
          propertyValue:
            colors.completedColor === 'Default' ? '' : colors.completedColor,
        },
        {
          clientId,
          propertyName: 'scheduleSlotAheadColor',
          propertyValue:
            colors.aheadColor === 'Default' ? '' : colors.aheadColor,
        },
        {
          clientId,
          propertyName: 'scheduleSlotBehindColor',
          propertyValue:
            colors.behindColor === 'Default' ? '' : colors.behindColor,
        },
      ]);

      if (isMutationSuccess(result)) {
        setColors((prevColors) => ({
          ...prevColors,
          inProgressColor: colors.inProgressColor,
          completedColor: colors.completedColor,
          aheadColor: colors.aheadColor,
          behindColor: colors.behindColor,
        }));

        enqueue({
          message: t('updated_client_settings_successfully'),
        });
      }
    },
    [clientId, colors, enqueue, saveProperties, t],
  );

  if (propertiesQuery.isLoading) {
    return <div>{t('Loading...')}</div>;
  }

  if (propertiesQuery.isError) {
    return <div>{t('Error loading properties')}</div>;
  }

  return (
    <QueryResolver query={propertiesQuery}>
      {() => (
        <>
          <div className="mb-6 mt-1 flex justify-end">
            <div className="flex items-center gap-8">
              <div className="flex items-center gap-2">
                <span className="text-base text-gray-500">
                  {t('Default Color')}:{' '}
                </span>
                <div
                  aria-hidden
                  className="h-5 w-5 shrink-0 overflow-hidden rounded-full border border-gray-200"
                  style={{
                    backgroundColor: '#6BC07D',
                  }}
                />
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <ColorSelector
              name="inProgressColor"
              label={t('In Progress')}
              value={colors.inProgressColor}
              onChange={handleColorChange}
            />
            <ColorSelector
              name="completedColor"
              label={t('Completed')}
              value={colors.completedColor}
              onChange={handleColorChange}
            />
            <ColorSelector
              name="aheadColor"
              label={t('Ahead')}
              value={colors.aheadColor}
              onChange={handleColorChange}
            />
            <ColorSelector
              name="behindColor"
              label={t('Behind')}
              value={colors.behindColor}
              onChange={handleColorChange}
            />
            <div className="flex justify-end space-x-3 pt-5">
              <Button
                kind="tertiary"
                size="compact"
                onClick={handleResetColors}
                disabled={Object.values(colors).every(
                  (color) => color === 'Default',
                )}
              >
                {t('Reset to Default Colors')}
              </Button>
              <Button
                type="submit"
                className="px-5 py-3"
                isLoading={mutation.isLoading}
              >
                {t('Save')}
              </Button>
            </div>
          </form>
        </>
      )}
    </QueryResolver>
  );
}

interface ColorSelectorProps {
  name: string;
  label: string;
  value: string;
  onChange: (name: string, value: string) => void;
}

const ColorSelector = React.memo(
  ({ name, label, value, onChange }: ColorSelectorProps) => {
    const { t } = useLocale();

    const getColorValue = (colorValue: string) => {
      if (colorValue === 'Default') {
        return DEFAULT_SCHEDULE_COLOR;
      }
      return (
        THEME_VALUES[colorValue as keyof typeof THEME_COLORS][
          '--brand-primary-500'
        ] || DEFAULT_SCHEDULE_COLOR
      );
    };

    return (
      <div className="mb-4 flex items-center justify-between">
        <div className="flex-1">
          <Label>{label}</Label>
          <SelectColor
            items={[
              { id: 'Default', value: 'Default' },
              ...Object.keys(THEME_COLORS).map((item) => ({
                id: item,
                value: item,
              })),
            ]}
            selectedKey={value}
            onSelectionChange={(key) => onChange(name, key as string)}
          >
            {(color) => (
              <SelectItem textValue={color.value}>
                <div className="flex items-center space-x-2">
                  <div
                    aria-hidden
                    className="h-5 w-5 shrink-0 overflow-hidden rounded-full"
                    style={{
                      backgroundColor: getColorValue(color.value),
                    }}
                  />
                  <span>{color.value}</span>
                </div>
              </SelectItem>
            )}
          </SelectColor>
        </div>
        <div className="ml-4 mt-7">
          <Button
            kind="tertiary"
            size="compact"
            onClick={() => onChange(name, 'Default')}
            disabled={value === 'Default'}
          >
            {t('Reset to Default')}
          </Button>
        </div>
      </div>
    );
  },
);

ColorSelector.displayName = 'ColorSelector';
