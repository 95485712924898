import { z } from 'zod';

import { Skill } from '../skills/types';
import { Address } from '../types.shared';

import { THEME_COLORS } from 'features/reseller/branding/constants';

const themeColorSchema = z.enum(
  Object.keys(THEME_COLORS) as [string, ...string[]],
);

export const CURRENCY_OPTION_VALUES = {
  USD: 'USD',
  CAD: 'CAD',
  MXN: 'MXN',
  PEN: 'PEN',
  COP: 'COP',
} as const;
export const currencyOptionSchema = z.nativeEnum(CURRENCY_OPTION_VALUES);

export type ResellerClient = {
  id: number;
  address: Address;
  timeZone: string;
  accountRep: string;
  businessName: string;
  firstName: string;
  lastName: string;
  mainContactName: string;
  mainContactPhone: string;
  mainContactEmail: string;
  billingContactName: string;
  billingContactPhone: string;
  billingContactEmail: string;
  masterSkills: Skill[];
  smtpCredentials: SMTPCredentials;
  lastWorkRequestNo: number;
  lastCustomerNo: number;
  isEnabled: boolean;
};

export type SMTPCredentials = {
  host: string;
  port: number;
  user: string;
  password: string;
  from: string;
  tls: 'Enabled' | 'Disabled';
  ssl: 'Enabled' | 'Disabled';
  // authMethod: string;
  // timeoutMS: number | null;
  // sendPartial: boolean | null;
  // to: string[] | null;
  // cc: string[] | null;
  // bcc: string[] | null;
  retryCount: number | null;
  debug: boolean | null;
};

const CLIENT_PROPERTY_KEYS = {
  clientLogo: 'clientLogo',
  currencyPreference: 'currencyPreference',
  workingHoursStart: 'workingHoursStart',
  workingHoursEnd: 'workingHoursEnd',
  telematicsEnabled: 'telematicsEnabled',
  vehiclesEnabled: 'vehiclesEnabled',
  telematicsRedirectUrl: 'telematicsRedirectUrl',
  paymentEnabled: 'paymentEnabled',
  paymentUrl: 'paymentUrl',
  driveTime: 'driveTime',
  reviewsUrl: 'reviewsUrl',
  countries: 'countries',
  emailScheduleConfirmationMessage: 'emailScheduleConfirmationMessage',
  emailJobCompleteMessage: 'emailJobCompleteMessage',
  emailNextDayReminderMessage: 'emailNextDayReminderMessage',
  emailOnTheWayMessage: 'emailOnTheWayMessage',
  emailDeliveredMessage: 'emailDeliveredMessage',
  sendScheduleConfirmationMessage: 'sendScheduleConfirmationMessage',
  sendOnTheWayMessage: 'sendOnTheWayMessage',
  sendNextDayReminderMessage: 'sendNextDayReminderMessage',
  sendJobCompleteMessage: 'sendJobCompleteMessage',
  sendDeliveredMessage: 'sendDeliveredMessage',
  scheduleSlotInProgressColor: 'scheduleSlotInProgressColor',
  scheduleSlotCompletedColor: 'scheduleSlotCompletedColor',
  scheduleSlotAheadColor: 'scheduleSlotAheadColor',
  scheduleSlotBehindColor: 'scheduleSlotBehindColor',
  helcimApiToken: 'helcimApiToken',
} as const;
const clientPropertyKeysSchema = z.nativeEnum(CLIENT_PROPERTY_KEYS);
export type ClientPropertyKey = z.infer<typeof clientPropertyKeysSchema>;

export const clientPropertySchema = z.object({
  propertyName: clientPropertyKeysSchema,
  propertyValue: z.string(),
  dateCreated: z.string(),
  dateModified: z.string(),
  isDeleted: z.boolean(),
  dateDeleted: z.string(),
});

export type ClientProperty = z.infer<typeof clientPropertySchema>;

// TODO: Object keys should be typed so that when we add new client property keys, we don't forget to add them here
const countriesSchema = z.coerce
  .string()
  .transform((val) => {
    if (!val) {
      return [];
    }
    try {
      return JSON.parse(val);
    } catch {
      return [];
    }
  })
  .catch([]);
export const mappedSchemaClientProperties = z.object({
  telematicsEnabled: z.coerce.boolean().catch(false),
  paymentEnabled: z.coerce.boolean().catch(false),
  vehiclesEnabled: z.coerce
    .string()
    .toLowerCase()
    .transform((val) => val === 'true')
    .catch(false),
  telematicsRedirectUrl: z.coerce.string().url().catch(''),
  paymentUrl: z.coerce.string().url().catch(''),
  clientLogo: z.coerce.string().catch(''),
  currencyPreference: currencyOptionSchema.catch('USD'),
  workingHoursStart: z.coerce.string(),
  workingHoursEnd: z.coerce.string(),
  driveTime: z.coerce.number().catch(0),
  reviewsUrl: z.coerce.string(),
  countries: countriesSchema,
  emailScheduleConfirmationMessage: z.coerce.boolean().catch(false),
  emailJobCompleteMessage: z.coerce.boolean().catch(false),
  emailNextDayReminderMessage: z.coerce.boolean().catch(false),
  emailOnTheWayMessage: z.coerce.boolean().catch(false),
  emailDeliveredMessage: z.coerce.boolean().catch(false),
  sendScheduleConfirmationMessage: z.coerce.boolean().catch(true),
  sendOnTheWayMessage: z.coerce.boolean().catch(true),
  sendNextDayReminderMessage: z.coerce.boolean().catch(true),
  sendJobCompleteMessage: z.coerce.boolean().catch(true),
  sendDeliveredMessage: z.coerce.boolean().catch(true),
  scheduleSlotInProgressColor: themeColorSchema.catch('Blue'),
  scheduleSlotCompletedColor: themeColorSchema.catch('Green'),
  scheduleSlotAheadColor: themeColorSchema.catch('Gold'),
  scheduleSlotBehindColor: themeColorSchema.catch('Red'),
  helcimApiToken: z.string().catch(''),
});

export const toMappedSchemaClientProperties = (
  properties: ClientProperty[],
) => {
  return mappedSchemaClientProperties.parse(
    properties.reduce((acc, property) => {
      acc[property.propertyName] = property.propertyValue;
      return acc;
    }, {} as Record<string, any>),
  );
};
